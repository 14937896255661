import React from 'react'

const AddEvents = () => {
    return (
        <div>
            Hello Penambahan
        </div>
    )
}

export default AddEvents
