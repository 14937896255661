import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from '../../components/PrivateRoute'
import { strings } from "res";
import Loading from 'components/shared-components/Loading';

// Pages
import Home from "./home";
import Events from "./events";
import AddEvents from "./add-events";

export const AppViews = ({match}) => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <PrivateRoute path={`${match.url}/${strings.navigation.route_path.home}`} component={Home} />
        <PrivateRoute path={`${match.url}/${strings.navigation.route_path.events}`} component={Events} />
        <PrivateRoute path={`${match.url}/${strings.navigation.route_path.add_events}`} component={AddEvents} />
        <PrivateRoute from={`${match.url}`} to={`${match.url}/${strings.navigation.route_path.events}`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;
