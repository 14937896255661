import React from 'react'
import LoginForm from '../../components/LoginForm'
import { Card, Row, Col } from "antd";
import { strings } from 'res';

const backgroundStyle = {
	backgroundImage: 'url(/gqsp_img/background-gqsp.png)',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover'
}

const Login = props => {
	return (
		<div className="h-100" style={backgroundStyle}>
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="center">
					<Col xs={20} sm={20} md={20} lg={7}>
						<Card>
							<div className="my-4">
								<div className="text-center">
									<img style={{width:"100%",padding:"10px"}} src="/gqsp_img/logo.png" alt=""></img>
									<p className="text-center">Dashboard for Admin</p>
								</div>
								<Row justify="center">
									<Col xs={24} sm={24} md={20} lg={20}>
										<LoginForm {...props} otherSignIn={null} redirect={strings.navigation.main}/>
									</Col>
								</Row>
								<p className="text-center">{strings.auth_form.isUnavailableAccount} 
									<a href={strings.navigation.register}>{strings.auth_form.sign_up}</a>
								</p>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default Login
