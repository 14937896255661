import { Tooltip } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import React from 'react'
import Utils from 'utils'

const ItemMember = ({member}) => {
    return (
        <>
            {member.map((elm, i) => (
                    i <= 2?
                <Tooltip title={elm.name} key={`avatar-${i}`}>
                    <Avatar size="small" className={`ml-1 cursor-pointer ant-avatar-${elm.avatarColor}`} src={elm.img} >
                        {elm.img? '' : <span className="font-weight-semibold font-size-sm">{Utils.getNameInitial(elm.name)}</span>}
                    </Avatar>
                </Tooltip>
                :
                null
            ))}
            {member.length > 3 ?
                <Tooltip title={`${member.length - 3} More`}>
                    <Avatar size={25} className="ml-1 cursor-pointer bg-white border font-size-sm">
                        <span className="text-gray-light font-weight-semibold">+{member.length - 3}</span>
                    </Avatar>
                </Tooltip>
                :
                null
            }
        </>    
    )
}

export default ItemMember
