import { Menu } from "antd";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import { 
	EyeOutlined, 
	EditOutlined,
	DeleteOutlined
} from '@ant-design/icons';

import React from 'react'

const ItemAction = ({id, removeId}) => {
    return (
        <EllipsisDropdown 
		menu={
			<Menu>
				<Menu.Item key="0">
					<EyeOutlined />
					<span>View</span>
				</Menu.Item>
				<Menu.Item key="1">
					<EditOutlined />
					<span>Edit</span>
				</Menu.Item>
				<Menu.Divider />
				<Menu.Item key="2" onClick={() => removeId(id)}>
					<DeleteOutlined />
					<span>Delete Project</span>
				</Menu.Item>
			</Menu>
		}
	/>
    )
}

export default ItemAction
