import React from 'react'

const ItemHeader = ({name, category}) => {
    return (
        <div>
            <h4 className="mb-0">{name}</h4>
            <span className="text-muted">{category}</span>
        </div>
    )
}

export default ItemHeader
