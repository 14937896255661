import {
    CheckCircleOutlined,
    ClockCircleOutlined, PaperClipOutlined
} from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import Flex from 'components/shared-components/Flex';
import React from 'react';

const ItemInfo = ({attachmentCount, completedTask, totalTask, statusColor, dayleft}) => {
    return (
            <Flex alignItems="center">
                <div className="mr-3">
                    <Tooltip title="Attachment">
                        <PaperClipOutlined className="text-muted font-size-md"/>
                        <span className="ml-1 text-muted">{attachmentCount}</span>
                    </Tooltip>
                </div>
                <div className="mr-3">
                    <Tooltip title="Task Completed">
                        <CheckCircleOutlined className="text-muted font-size-md"/>
                        <span className="ml-1 text-muted">{completedTask}/{totalTask}</span>
                    </Tooltip>
                </div>
                <div>
                <Tag className={statusColor === "none"? 'bg-gray-lightest' : ''} color={statusColor !== "none"? statusColor : ''}>
                    <ClockCircleOutlined />
                    <span className="ml-2 font-weight-semibold">{dayleft} days left</span>
                </Tag>
                </div>
            </Flex>
    )
}

export default ItemInfo
