export const strings = {
    auth_form: {
      isUnavailableAccount: "Don't have an account yet?",
      alreadyHaveAccount:"Already Have an Account?",
      sign_up: "Sign Up",
      sign_in: "Sign In",
      create_account: "Create a new account:",
    },
    default: {
        title_app: "GQSP Indonesia",
    },
    menu: {
      pages: 'Pages',
      home: 'Home',
      events: 'Events',
      homePage: 'Home Page',
      aboutPage: 'About Page',
      valuesPage: 'Value Page',
      resourcesPage: 'Resources Page',
      eventsPage: 'Events Page',
      storiesPage: 'Stories Page'
    },
    navigation: {
      login: '/auth/login',
      register: '/auth/register',
      main: '/app',
      page: {
        home: '/app/home',
        events: '/app/events',
        homePage: '/app/home-page',
        aboutPage: '/app/about-page',
        valuesPage: '/app/values-page',
        resourcesPage: '/app/resources-page',
        eventsPage: '/app/events-page'
      },
      route_path: {
        login: 'login',
        register: 'register',
        forgot_password: 'forgot-password',
        error_one: 'error-1',
        error_two: 'error-2',
        home: 'home',
        events: 'events',
        add_events: 'add-events',
      }
    }
};
  