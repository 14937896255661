import {
    AppstoreOutlined,
    PlusOutlined, UnorderedListOutlined
} from '@ant-design/icons';
import { Button, Card, Col, Radio, Row } from 'antd';
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt';
import Flex from 'components/shared-components/Flex';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ProjectListData, strings } from 'res';
import ItemAction from './components/ItemAction';
import ItemHeader from './components/ItemHeader';
import ItemInfo from './components/ItemInfo';
import ItemMember from './components/ItemMember';

const VIEW_LIST = 'LIST';
const VIEW_GRID = 'GRID';

const ListItem = ({ data, removeId }) => (
	<div className="bg-white rounded p-3 mb-3 border">
		<Row align="middle">
    	<Col xs={24} sm={24} md={8}>
				<ItemHeader name={data.name} category={data.category} />
			</Col>
			<Col xs={24} sm={24} md={6}>
				<ItemInfo 
					attachmentCount={data.attachmentCount}
					completedTask={data.completedTask}
					totalTask={data.totalTask}
					statusColor={data.statusColor}
					dayleft={data.dayleft}
				/>
			</Col>
			<Col xs={24} sm={24} md={3}>
				<div className="ml-0 ml-md-3">
					<ItemMember member={data.member}/>
				</div>
			</Col>
			<Col xs={24} sm={24} md={2}>
				<div className="text-right">
					<ItemAction id={data.id} removeId={removeId}/>
				</div>
			</Col>
		</Row>
	</div>
)

const GridItem = ({ data, removeId }) => (
	<Card>
		<Flex alignItems="center" justifyContent="between">
			<ItemHeader name={data.name} category={data.category} />
			<ItemAction id={data.id} removeId={removeId}/>
		</Flex>
		<div className="mt-2">
			<ItemInfo 
				attachmentCount={data.attachmentCount}
				completedTask={data.completedTask}
				totalTask={data.totalTask}
				statusColor={data.statusColor}
				dayleft={data.dayleft}
			/>
		</div>
		<div className="mt-2">
			<ItemMember member={data.member}/>
		</div>
	</Card>
)

const Events = () => {

	let history = useHistory();

	const [view, setView] = useState(VIEW_GRID);
	const [list, setList] = useState(ProjectListData);

	const onChangeProjectView = e => {
		setView(e.target.value)
	}

	const deleteItem = id =>{
		const data = list.filter(elm => elm.id !== id)
		setList(data)
	}

	const navigateTo = (value) => {
		history.push(value)
	}

	return (
		<>
			<PageHeaderAlt className="bg-white border-bottom">
				<div className="container-fluid">
					<Flex justifyContent="between" alignItems="center" className="py-4">
						<h2>{strings.menu.events}</h2>
						<div>
							<Radio.Group defaultValue={VIEW_GRID} onChange={e => onChangeProjectView(e)}>
								<Radio.Button value={VIEW_GRID}><AppstoreOutlined /></Radio.Button>
								<Radio.Button value={VIEW_LIST}><UnorderedListOutlined /></Radio.Button>
							</Radio.Group>
							<Button type="primary" className="ml-2" onClick={() => navigateTo(strings.navigation.route_path.add_events)}>
								<PlusOutlined />
								<span>New</span>
							</Button>
						</div>
					</Flex>
				</div>
			</PageHeaderAlt>
			<div className={`my-4 ${view === VIEW_LIST? 'container' : 'container-fluid'}`}>
				{
					view === VIEW_LIST ?
					list.map(elm => <ListItem data={elm} removeId={id => deleteItem(id)} key={elm.id}/>)
					:
					<Row gutter={16}>
						{list.map(elm => (
							<Col xs={24} sm={24} lg={8} xl={8} xxl={6} key={elm.id}>
								<GridItem data={elm} removeId={id => deleteItem(id)}/>
							</Col>
						))}
					</Row>
				}
			</div>
		</>
	)
}

export default Events
