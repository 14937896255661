import { 
  DashboardOutlined, 
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  StopOutlined,
  DotChartOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  FundOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  FileUnknownOutlined,
  ProfileOutlined,
  ScheduleOutlined
} from '@ant-design/icons';

import { strings } from 'res';

const dashBoardNavTree = [{
  key: strings.menu.home,
  path: strings.navigation.page.home,
  title: strings.menu.home,
  icon: EnvironmentOutlined,
  breadcrumb: false,
  submenu: [{
    key: strings.menu.home,
    path: strings.navigation.page.home,
    title: strings.menu.home,
    icon: LayoutOutlined,
    breadcrumb: false,
    submenu: []
  }]
}]

const pageNavTree = [{
  key: strings.menu.pages,
  path: strings.navigation.page.homePage,
  title: strings.menu.pages,
  icon: LayoutOutlined,
  breadcrumb: false,
  submenu: [{
    key: strings.menu.pages,
    path: strings.navigation.page.homePage,
    title: strings.menu.homePage,
    icon: FileDoneOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: strings.menu.pages,
    path: strings.navigation.page.aboutPage,
    title: strings.menu.aboutPage,
    icon: LayoutOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: strings.menu.pages,
    path: strings.navigation.page.valuesPage,
    title: strings.menu.valuesPage,
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: []
  },{
    key: strings.menu.pages,
    path: strings.navigation.page.storiesPage,
    title: strings.menu.storiesPage,
    icon: CommentOutlined,
    breadcrumb: false,
    submenu: []
  },{
    key: strings.menu.pages,
    path: strings.navigation.page.resourcesPage,
    title: strings.menu.resourcesPage,
    icon: BookOutlined,
    breadcrumb: false,
    submenu: []
  },{
    key: strings.menu.pages,
    path: strings.navigation.page.eventsPage,
    title: strings.menu.eventsPage,
    icon: ScheduleOutlined,
    breadcrumb: false,
    submenu: []
  }
]
}]

const addNavTree = [{
  key: strings.menu.pages,
  path: strings.navigation.page.homePage,
  title: "Add Resources",
  icon: LayoutOutlined,
  breadcrumb: false,
  submenu: [{
    key: strings.menu.pages,
    path: strings.navigation.page.homePage,
    title: "Add Resources",
    icon: FileDoneOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: strings.menu.pages,
    path: strings.navigation.page.aboutPage,
    title: "Add Stories",
    icon: LayoutOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: strings.menu.pages,
    path: strings.navigation.page.valuesPage,
    title: "Add Events",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: []
  }
]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...pageNavTree,
  ...addNavTree
]

export default navigationConfig;
